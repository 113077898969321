body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: var(--grey-dark-3);
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

button,
input {
  background-color: transparent;
  font-family: inherit;
}

form {
  padding: none;
  margin: none;
}

/* Variables */

:root {
  /* General */
  --em: 8px;
  --radius: 3px;
  --transition: all 140ms ease-in-out;
  --border: 2px;

  /* Color */
  --background: #eaf3f9;
  --white: #fff;
  --grey-light-3: #fdfdfd;
  --grey-light-2: #f6f6f6;
  --grey-light-1: #e4e4e4;
  --grey: #9f9f9f;
  --grey-dark-1: #777777;
  --grey-dark-2: #454545;
  --grey-dark-3: #000;

  --primary-light-2: #faf8ff;
  --primary-light-1: #c7d2d8;
  --primary: #66ff99;
  --primary-disabled: rgba(102, 255, 153, 0.5);
  --primary-dark-2: #3600a1;

  --team-1: #fe4164;
  --team-1-accent: rgba(0, 0, 0, 0.48);
  --team-2: #5398ff;
  --team-2-accent: rgba(0, 0, 0, 0.48);
  /* --neutral: #dee3e3; */
  --neutral: rgba(215, 189, 125, 1);
  --neutral-accent: rgba(0, 0, 0, 0.48);
  --death: #222;
  --death-accent: #ffff00;

  /* Fonts */
  --text: 16px;
  --text-tiny: calc(var(--em) * 0.7);
  --text-small: calc(var(--text) * 0.8);
  --text-large: calc(var(--text) * 1);
  --text-big: calc(var(--text) * 2.2);
  --text-light: 300;
  --text-regular: 400;
  --text-medium: 500;
  --text-semi-bold: 600;
  --text-heavy: 700;
}
